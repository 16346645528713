
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import RecipientList from '@/components/shared/RecipientList.vue'
import MemberInfo from '@/models/MemberInfo'
import Recipient from '@/models/recipient/Recipient'
import { RecipientSkeletonFields } from '@/gateway/recipient/model/RecipientSkeletonFields'
import RecipientSheetHandler from '@/lib/RecipientSheetHandler'
import { ReceiveMethodEnum } from '@/enums'
import { MasterCode } from '@/gateway/sheet/MasterCode'
import { GetRecipients, GetRecipientsParam } from '@/gateway/recipient/GetRecipients'
import { AvailableCurrencies } from '@/gateway/remittanceBase/AvailableCurrencies'
import { staticValues } from '@/static/StaticValues'
import { GetRecipientFieldSkeleton } from '@/gateway/recipient/GetRecipientFieldSkeleton'
import CountryEnum from '@/enums/CountryEnum'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'

@Component({
  components: { RecipientList }
})
export default class Recipients extends Vue {
  masterCodeLabels: Dictionary<string> = MasterCode.getMasterCodeLabels()
  memberInfo: MemberInfo | undefined
  title: string = ''
  recipientExcludeFilter: Array<string> = [
    'id',
    'corp_id'
  ]
  recipientsUnit = 400
  recipients: Array<Recipient> = []
  countryRecipients: Dictionary<Array<Dictionary<any>>> = {}
  countryRecipientField: Array<RecipientSkeletonFields> = []
  sheetHandler!: RecipientSheetHandler

  async requestRecipientField (): Promise<Array<RecipientSkeletonFields>> {
    const countries = staticValues.availableCountriesIso as Array<keyof typeof CountryEnum | 'ZZ'>
    const remittanceMethodList = await GetRecipientFieldSkeleton.getInstance().request({ countries })
    return remittanceMethodList
  }

  async getRecipients (page: number = 1): Promise<void> {
    if (!this.memberInfo) {
      if (!MemberInfoGateway.hasAuthInfo()) {
        await this.$router.push('/signIn')
        return
      }
      this.memberInfo = MemberInfoGateway.getMemberInfo()
    }
    const getRecipientsParam: GetRecipientsParam = {
      corp_id: this.memberInfo.corp_id,
      page,
      unit: this.recipientsUnit
    }
    const getRecipientsResponse = await GetRecipients.getInstance().request(getRecipientsParam)
    const recipients: Array<Recipient> = getRecipientsResponse.list
    this.recipients = this.recipients.concat(recipients)
    const totalCount: number = getRecipientsResponse.total_count
    const existCount: number = this.recipients.length
    const hasMoreSheetRecipients = totalCount > existCount
    if (hasMoreSheetRecipients) {
      await this.getRecipients(++page)
    }
  }

  matchRecipientWithField (recipient: Recipient): Dictionary<any> {
    return {
      corps_id: recipient.corp_pid,
      name_first: recipient.first_name,
      name_middle: recipient.middle_name,
      name_last: recipient.last_name,
      nation: recipient.nationality,
      birth_date: recipient.birth,
      phone_code: recipient.nationality,
      phone: recipient.phone_number,
      address_postal: recipient.postal_code,
      address_city: recipient.city,
      address_line1: recipient.line1,
      card_number: recipient.remittance_method_data.debit_card_number,
      master_code: this.masterCodeLabels[recipient.master_code],
      account_type: recipient.remittance_method_data.bank_account_type,
      ifsc: recipient.remittance_method_data.bank_ifsc,
      account_number: recipient.remittance_method_data.bank_account_number,
      iban: recipient.remittance_method_data.bank_iban,
      bank_branch: recipient.remittance_method_data.bank_branch,
      sort_code: recipient.remittance_method_data.bank_sort_code,
      transit_code: recipient.remittance_method_data.bank_transit_code,
      bsb_code: recipient.remittance_method_data.bank_bsb_code,
      swift: recipient.remittance_method_data.bank_swift,
      corresponding_bank_swift: recipient.remittance_method_data.corresponding_bank_swift,
      wingmoney_number: recipient.remittance_method_data.payment_gateway_wing_money_account_number,
      routing_number: recipient.remittance_method_data.bank_routing_number,
      base_currency: recipient.currency
    }
  }

  deleteEmptySheet () {
    Object.keys(this.countryRecipients).forEach(country => {
      const hasHeaderOnlyCountry = this.countryRecipients[country].length < 2
      if (hasHeaderOnlyCountry) delete this.countryRecipients[country]
    })
  }

  sortCountryRecipients (fieldName: string) {
    Object.keys(this.countryRecipients).forEach(country => {
      const excludeLocaleNameList: Array<Dictionary<any>> = this.countryRecipients[country].splice(1)
      const sortedExcludeLocaleNameList = excludeLocaleNameList.sort((a, b) => {
        return a[fieldName] < b[fieldName] ? -1 : (a[fieldName] > b[fieldName] ? 1 : 0)
      })
      this.countryRecipients[country] = this.countryRecipients[country].concat(sortedExcludeLocaleNameList)
    })
  }

  filterRecipientsCountry (): void {
    this.recipients.forEach(recipient => {
      const receiveMethod = ReceiveMethodEnum[recipient.remittance_method_type]
      const countryWithMethod: string = `${recipient.country}_${receiveMethod}`
      const recipientFields: Dictionary<any> = {}
      const tempRecipientFields: Dictionary<any> = this.matchRecipientWithField(recipient)
      if (!this.countryRecipients[countryWithMethod]) return
      const fields: Array<string> = Object.keys(this.countryRecipients[countryWithMethod][0])
      fields.forEach(field => {
        if (!field) return
        recipientFields[field] = tempRecipientFields[field]
      })
      this.countryRecipients[countryWithMethod].push(recipientFields)
    })
  }

  setTotalCountTitle (totalPerson: number) {
    this.title = `${this.$t('menu.recipients_list')} ${this.$t('commons.total_person', { total_person: totalPerson })}`
  }

  async setCountryRecipients (): Promise<void> {
    this.countryRecipientField = await this.requestRecipientField()
    await this.getRecipients(1)
    const receivableCurrencies = await AvailableCurrencies.getReceivableCurrencies()
    this.sheetHandler = new RecipientSheetHandler()
    this.countryRecipients = this.sheetHandler.makeSheetJson(this.countryRecipientField, receivableCurrencies)
    this.filterRecipientsCountry()
    this.deleteEmptySheet()
    await this.sortCountryRecipients('corps_id')
  }

  async downloadRecipients (): Promise<void> {
    const isEmptyObject: boolean = !Object.keys(this.countryRecipients).length
    if (isEmptyObject) await this.setCountryRecipients()
    this.sheetHandler.setSheetJsonToExport(this.countryRecipients)
    this.sheetHandler.saveSheetByJson('recipients_list.xlsx', { useCellDataOption: false, cellDataOptions: { account_number: { type: 's' } } })
  }

  created () {
    if (!MemberInfoGateway.hasAuthInfo()) {
      this.$router.push('/signIn')
      return
    }
    const memberInfo: MemberInfo = MemberInfoGateway.getMemberInfo()
    if (memberInfo.aud === 'biz_temp') this.$router.push('/affiliate/documents')
  }
}
